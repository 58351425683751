import React from "react"
import Layout from "../components/layout"
import { Leif } from "../components/Images/leif"
import { Renee } from "../components/Images/renee"
import { MapPin } from "../components/Images/mapPin"

const ContactPage = () => (
  <Layout>
    <h2>Kontakt</h2>
    <h4>Våre kjørelærere</h4>

    <div className={"teacher"}>
      <Renee />
      <p>
        Renée Marianne Bratli <br />
        Daglig/faglig leder <br />
        Mobil <a href="tel:90084657">90 08 46 57</a>
        <br />
      </p>
    </div>
    <br />
    <div className={"teacher"}>
      <Leif />
      <p>
        Leif Erik Svensen <br />
        Trafikklærer <br />
        Mobil <a href="tel:90637654">90 63 76 54</a>
        <br />
      </p>
    </div>
    <br />

    <br />
    <br />
    <br />

    <h4>Kontaktinformasjon</h4>
    <div>
      <table>
        <tr>
          <td>Tlf</td>
          <td className={"rightcolumn"}>
            <a href="tel:38092800">38 09 28 00</a>
          </td>
        </tr>
        <tr>
          <td>Adresse:</td>
          <td className={"rightcolumn"}>
            Festningsgata 38&nbsp;&nbsp;
            <MapPin />
          </td>
        </tr>
        <tr>
          <td>Epost</td>
          <td className={"rightcolumn"}>
            <a href="mailto:rmbtrafikkskole@online.no">
              rmbtrafikkskole@online.no
            </a>
          </td>
        </tr>
      </table>
    </div>
  </Layout>
)

export default ContactPage
